import React from 'react';
import { Layout, SEO } from 'components';
import { Homepage } from 'containers';

const IndexPage = () => (
  <Layout>
    <SEO title='Home' />
    <Homepage />
  </Layout>
);

export default IndexPage;
